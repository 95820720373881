<div class="table-wrapper flex flex-column w-full">
  <div class="table-header">
    <h4>{{ rowsLength }} {{ countLabel }}:</h4>
  </div>
  <p-skeleton
    class="table-skeleton"
    *ngIf="!data"
    width="100%"
    height="200px"
  />
  <div class="table-scrollable" *ngIf="data">
    <p-table
      *ngIf="data"
      #innerTable
      [value]="data"
      [scrollHeight]="scrollHeight"
      [scrollable]="true"
      [globalFilterFields]="getFields()"
      class="p-datatable p-component custom-table"
    >
      <ng-template pTemplate="header">
        <tr>
          <div *ngIf="!data">
            <p-skeleton></p-skeleton>
          </div>
          <th *ngFor="let col of columns" class="text-sm font-bold">
            {{ col.header | translate }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <td
            *ngFor="let col of columns"
            class="table-cell text-sm font-normal"
          >
            <div *ngIf="!data">
              <p-skeleton></p-skeleton>
            </div>

            <div *ngIf="data.length > 0">
              @switch (col.type) {
                @case (TableColumnType.TRANSLATION) {
                  <span>
                    {{
                      getTranslationKey(
                        col.translationKey,
                        getFieldData(row, col.field)
                      ) | translate
                    }}
                  </span>
                }
                @case (TableColumnType.ACTION) {
                  <span>
                    <!--                    <div-->
                    <!--                      class="flex flex-column align-items-start"-->
                    <!--                      *ngIf="shouldShowIcon(col, row)"-->
                    <!--                    >-->
                    <!--                      <i-->
                    <!--                        (click)="onIconClick(col, row)"-->
                    <!--                        class="{{ col.icon }} cursor-pointer"-->
                    <!--                      ></i>-->
                    <!--                    </div>-->
                    <div class="flex gap-2">
                      <ng-container *ngFor="let iconConfig of col.icons">
                        <i
                          *ngIf="
                            !iconConfig.showCondition ||
                            iconConfig.showCondition(row)
                          "
                          (click)="iconConfig.action(row)"
                          class="{{ iconConfig.icon }} cursor-pointer"
                        ></i>
                      </ng-container>
                    </div>
                  </span>
                }
                @case (TableColumnType.ENUM) {
                  <span>
                    {{ getEnumKey(col.enumName, getFieldData(row, col.field)) }}
                  </span>
                }
                @case (TableColumnType.DATE) {
                  <span>
                    {{ getFieldData(row, col.field) | date }}
                  </span>
                }
                @case (TableColumnType.STRING) {
                  <span
                    [pTooltip]="shouldShowTooltip(getFieldData(row, col.field))"
                    tooltipPosition="bottom"
                  >
                    {{ getFieldData(row, col.field) | truncate: 50 }}
                  </span>
                }
              }
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div *ngIf="data.length === 0" class="table-empty gap-4 flex flex-column">
      <img src="assets/images/no_iot_data.svg" alt="Logo" class="logo-nodata" />
      <span>{{ 'features.trace-batch-page.no-information' | translate }}</span>
    </div>
  </div>
</div>
