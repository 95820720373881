<div class="background-image w-full flex flex-column justify-content-around">
  <app-label-description-custom
    [icon]="'pi pi-tag'"
    [title]="'features.shipment-page.new-shipment' | translate"
  ></app-label-description-custom>
  <form
    [formGroup]="shipmentForm"
    class="flex flex-column w-full align-items-start justify-content-between height-upsert p-5 flex-grow"
    (ngSubmit)="onInsertShipment()"
  >
    <div class="h-full w-full">
      <div class="flex flex-column w-full">
        <span class="font-bold py-2">{{
            'features.shipment-page.label-1' | translate
          }}</span>
        <div class="flex flex-column w-full">
          <div class="flex flex-column md:flex-row md:gap-4">
            <div class="w-full p-fluid md:w-3 py-5">
              <p-floatLabel class="w-full">
                <p-dropdown
                  [style]="{ height: '2.7rem' }"
                  [options]="batches"
                  optionValue="_id"
                  optionLabel="code"
                  [placeholder]="'shared.search' | translate"
                  formControlName="batchCode"
                >
                </p-dropdown>
                <label>
                  {{ 'features.shipment-page.batch-label' | translate }}</label
                >
              </p-floatLabel>
            </div>
            <div class="w-full p-fluid md:w-3 py-5">
              <p-floatLabel class="w-full">
                <p-dropdown
                  [style]="{ height: '2.7rem' }"
                  [options]="customers"
                  optionValue="_id"
                  optionLabel="businessName"
                  [placeholder]="'shared.search' | translate"
                  formControlName="customer"
                >
                </p-dropdown>
                <label>
                  {{
                    'features.shipment-page.customer-label' | translate
                  }}</label
                >
              </p-floatLabel>
            </div>
            <div class="w-full p-fluid md:w-3 py-5">
              <p-floatLabel class="w-full">
                <p-inputNumber
                  formControlName="quantity"
                  [showButtons]="false"
                />
                <span class="input-text-right">Kg</span>
                <label>
                  {{
                    'features.shipment-page.quantity-label' | translate
                  }}</label
                ></p-floatLabel
              >
            </div>
            <div class="w-full p-fluid md:w-3 py-5">
              <p-floatLabel class="w-full">
                <p-calendar
                  formControlName="shipmentDate"
                  [showIcon]="true"
                  [showOnFocus]="false"
                  [showTime]="true"
                  hourFormat="24"
                  inputId="buttondisplay"
                />
                <label>
                  {{
                    'features.shipment-page.date-shipment-label'
                      | translate
                  }}
                </label>
              </p-floatLabel>
            </div>
          </div>
          <div
            class="flex flex-column w-full"
            [ngClass]="{
              'disabled-section': !shipmentForm.value['customer'],
            }"
          >
            <span class="font-bold py-2">{{
                'features.shipment-page.label-2' | translate
              }}</span>
            <div class="flex flex-column md:flex-row md:gap-4">
              <div class="w-full p-fluid md:w-3 py-5 relative">
                <div class="flex flex-row align-items-center">
                  <p-floatLabel class="w-full">
                    <p-inputNumber
                      [styleClass]="'button-color'"
                      formControlName="qrCodeQuantity"
                      [showButtons]="true"
                    ></p-inputNumber>
                    <label>
                      {{
                        'features.shipment-page.qrquantity-label' | translate
                      }}
                    </label>
                    <small class="qr-code-quantity-help">
                      {{
                        availableQRCodes +
                        ' ' +
                        ('features.shipment-page.qrquantity-help' | translate)
                      }}
                    </small>
                  </p-floatLabel>
                  <i
                    class="ml-3 pi pi-info-circle text-sm"
                    [pTooltip]="'features.shipment-page.tooltip' | translate"
                    tooltipPosition="right"
                  ></i>
                </div>
              </div>
              <div class="w-full p-fluid md:w-3 py-5" [hidden]="!shipmentForm.controls['rfid'].value">
                <div class="flex flex-row align-items-center">
                  <p-floatLabel class="w-full">
                    <input pInputText type="text"
                           formControlName="rfid"
                    />
                    <label>
                      {{
                        'features.shipment-page.rfid-label' | translate
                      }}</label
                    >
                  </p-floatLabel>
                  <i class="ml-3 pi pi-times-circle text-lg" (click)="clearRfidValue()"
                  ></i>
                </div>
              </div>
              <div class="w-full p-fluid md:w-3 py-1" [hidden]="shipmentForm.controls['rfid'].value || !rfidDeviceId">
                <div class="py-1">
                  <label class="">{{
                      'features.shipment-page.rfid-label' | translate
                    }}</label>
                </div>
                <div class="py-1">
                  <p-button label="{{
                      'features.shipment-page.rfid-pair' | translate
                    }}" [text]="true" [raised]="true" (onClick)="showRfidModal()"
                            [disabled]="!shipmentForm.value['customer']" />
                </div>
              </div>
              <div class="hidden md:flex w-full p-fluid md:w-3 py-5"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row w-full justify-content-between py-2">
      <p-button
        [icon]="'pi pi-chevron-left'"
        [label]="'shared.back' | translate"
        [outlined]="true"
        severity="secondary"
        [styleClass]="'secondary-custom'"
        (onClick)="openDialogBack()"
      />
      <p-button
        [label]="'shared.create' | translate"
        [raised]="true"
        [styleClass]="'primary-custom'"
        [disabled]="shipmentForm.invalid"
        (onClick)="openDialogConfirm()"
      />
    </div>
    <p-dialog
      [modal]="true"
      [(visible)]="dialogInsertVisible"
      [breakpoints]="{ '1199px': '60vw', '575px': '90vw' }"
    >
      <ng-template pTemplate="header">
        <div class="inline-flex align-items-center justify-content-start gap-2">
          <i class="pi pi-exclamation-circle text-white"></i>
          <span class="text-white text-2xl white-space-nowrap">
            {{ 'shared.attention' | translate }}
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <div class="flex flex-column gap-3">
          <span
            class="font-bold text-lg text-black-alpha-90 white-space-nowrap"
          >
            {{ 'shared.message-confirm-shipment-1' | translate }}
          </span>
          <p class="flex flex-row m-0">
            <span class="text-lg text-black-alpha-90 white-space-nowrap">
              {{ 'shared.message-shipment-range' | translate }}
            </span>
            <span
              class="text-lg font-bold text-black-alpha-90 white-space-nowrap"
            >
              &nbsp;{{ range.min }}&nbsp;
            </span>
            <span class="text-lg text-black-alpha-90 white-space-nowrap">
              {{ 'shared.message-shipment-range-1' | translate }}
            </span>
            <span
              class="text-lg font-bold text-black-alpha-90 white-space-nowrap"
            >
              &nbsp; {{ range.max }}&nbsp;
            </span>
          </p>
          <span class="text-lg text-black-alpha-90 white-space-nowrap">
            {{ 'shared.message-shipment-range-2' | translate }}
          </span>
          <span class="text-lg text-black-alpha-90 white-space-nowrap">
            {{ 'shared.message-go-back-2' | translate }}
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <div class="flex flex-row w-full justify-content-end gap-2 p-2">
          <p-button
            [hidden]="status_saving"
            [label]="'shared.cancel' | translate"
            [outlined]="true"
            severity="secondary"
            [styleClass]="'secondary-custom'"
            (onClick)="closeDialogConfirm()"
          />
          <p-button
            [loading]="status_saving"
            type="submit"
            [label]="'shared.confirm' | translate"
            [raised]="true"
            [styleClass]="'primary-custom'"
          />
        </div>
      </ng-template>
    </p-dialog>
  </form>
</div>
<p-dialog
  [modal]="true"
  [(visible)]="dialogGoBackVisible"
  [style]="{ width: '50rem' }"
  [breakpoints]="{ '1199px': '60vw', '575px': '90vw' }"
>
  <ng-template pTemplate="header">
    <div class="inline-flex align-items-center justify-content-start gap-2">
      <i class="pi pi-exclamation-circle text-white"></i>
      <span class="text-white text-2xl white-space-nowrap">
        {{ 'shared.attention' | translate }}
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="flex flex-column gap-3">
      <span
        class="font-bold text text-black-alpha-90 text-lg white-space-nowrap"
      >
        {{ 'shared.message-go-back-1' | translate }}
      </span>
      <span class="text-lg text text-black-alpha-90 white-space-nowrap">
        {{ 'shared.message-go-back-2' | translate }}
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex flex-row w-full justify-content-end gap-2 p-2">
      <p-button
        [label]="'shared.cancel' | translate"
        [outlined]="true"
        severity="secondary"
        [styleClass]="'secondary-custom'"
        (onClick)="closeDialogBack()"
      />
      <p-button
        type="submit"
        [label]="'shared.confirm' | translate"
        [raised]="true"
        [styleClass]="'primary-custom'"
        (onClick)="goBack()"
      />
    </div>
  </ng-template>
</p-dialog>

<p-dialog
  [modal]="true"
  [(visible)]="showScanModal"
  (onHide)="closeRfidScan()"
  [breakpoints]="{ '1199px': '60vw', '575px': '90vw' }"
>
  <ng-template pTemplate="header">
    <div class="inline-flex align-items-center justify-content-start gap-2">
      <i class="pi pi-tag text-white"></i>
      <span class="text-white text-2xl white-space-nowrap">
            {{ 'features.shipment-page.rfid-pair' | translate }}
          </span>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="flex justify-content-center flex-wrap">
      <img src="assets/images/rfid_scanner.png" alt="" class="logo-gray-style" />
    </div>
    <div class="flex flex-column gap-3 px-8 pt-4 ">
      {{ 'features.shipment-page.rfid-modal-text-1' | translate }}
    </div>
    <div class="flex flex-column gap-3 px-8 py-2 ">
      {{ 'features.shipment-page.rfid-modal-text-2' | translate }}
    </div>
    <div class="flex justify-content-center flex-wrap">
      <p-knob [(ngModel)]="countdown" [max]="countdownInt" />
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex justify-content-center flex-wrap">
      <p-button
        [label]="'shared.cancel' | translate"
        [outlined]="true"
        severity="secondary"
        [styleClass]="'secondary-custom'"
        (onClick)="closeRfidScan()"
      />
    </div>
  </ng-template>
</p-dialog>
