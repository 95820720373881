import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { TableColumnInterface } from '../../../../shared/components/table-custom/table-custom.interface';
import { TableColumnType } from '../../../../core/enums/table-column-type';
import { RoutingResourceEnum } from '../../../../core/enums/routing-resource-enum';
import { RoutingActionEnum } from '../../../../core/enums/routing-action-enum';
import { ResponseBatchDto } from '../../dto/response-batch.dto';
import { BatchService } from '../../services/batch.service';
import { Button, ButtonDirective } from 'primeng/button';
import { FilterSearchCustomComponent } from '../../../../shared/components/filter-search-custom/filter-search-custom.component';
import { LabelDescriptionCustomComponent } from '../../../../shared/components/label-description-custom/label-description-custom.component';
import { TableCustomComponent } from '../../../../shared/components/table-custom/table-custom.component';
import { ToastModule } from 'primeng/toast';
import { TranslateModule } from '@ngx-translate/core';
import { IndexResponseProductionSiteDto } from '../../../production-sites/dto/index-response-production-site.dto';
import { IndexResponseBatchDto } from '../../dto/index-response-batch.dto';
import { ResponseAttachmentDto } from '../../../../core/models/response-attachment.dto';
import { DialogAttachmentCustomComponent } from '../../../../shared/components/dialog-attachment-custom/dialog-attachment-custom.component';
import { DividerModule } from 'primeng/divider';
import { NgClass, NgForOf, NgIf, NgStyle } from '@angular/common';
import { StringifyFileSizePipe } from '../../../../core/pipe/stringify-pipe/stringify-file-size.pipe';
import { ExtensionsEnum } from '../../../../core/enums/extensions-enum';

@Component({
  selector: 'app-batch',
  standalone: true,
  imports: [
    Button,
    FilterSearchCustomComponent,
    LabelDescriptionCustomComponent,
    TableCustomComponent,
    ToastModule,
    TranslateModule,
    ButtonDirective,
    DialogAttachmentCustomComponent,
    DividerModule,
    NgForOf,
    NgIf,
    StringifyFileSizePipe,
    NgStyle,
    NgClass,
  ],
  templateUrl: './search-batch.component.html',
  styleUrl: './search-batch.component.scss',
})
export class SearchBatchComponent implements OnInit {
  constructor(
    private router: Router,
    private batchService: BatchService,
  ) {}

  @ViewChild('table') table!: Table;
  searchQuery: string = '';
  cols: TableColumnInterface<ResponseBatchDto>[] = [
    {
      type: TableColumnType.STRING,
      field: 'code',
      header: 'features.batch-page.table-header.batch-number',
    },
    {
      type: TableColumnType.STRING,
      field: 'product.name',
      header: 'features.batch-page.table-header.product',
    },
    {
      type: TableColumnType.DATE,
      field: 'seedingDate',
      header: 'features.batch-page.table-header.seeding-date',
    },
    {
      type: TableColumnType.STRING,
      field: 'productionSite.name',
      header: 'features.batch-page.table-header.production-site',
    },
    {
      type: TableColumnType.ACTION,
      header: 'features.batch-page.table-header.attachments',
      icons: [
        {
          icon: 'pi pi-file-o',
          action: (rowData) => this.viewAttachments(rowData),
          showCondition: (rowData) => this.shouldShowAttachmentIcon(rowData),
        },
      ],
    },
    {
      type: TableColumnType.ACTION,
      header: '',
      icons: [
        {
          icon: 'pi pi-pencil',
          action: (rowData) => this.goToEdit(rowData._id),
        },
      ],
    },
  ];
  dataTable!: ResponseBatchDto[];
  attachments!: ResponseAttachmentDto[];
  selectedAttachment: boolean = false;
  dialogAttachmentsVisible: boolean = false;
  batch!: ResponseBatchDto;
  selectedAttachmentName!: string;
  selectedAttachmentFileKey!: string;
  pdfUrl!: string;

  ngOnInit(): void {
    this.getBatches();
  }

  getBatches() {
    this.batchService.index().subscribe({
      next: (batch: ResponseBatchDto[]) => {
        this.dataTable = batch;
      },
      error: (err) => {},
    });
  }

  goHome() {
    void this.router.navigate([RoutingResourceEnum.HOME]);
  }

  goToInsert() {
    void this.router.navigate(
      [`${RoutingResourceEnum.BATCHES}/${RoutingActionEnum.INSERT}`],
      {
        state: {
          originRoute: `${RoutingResourceEnum.BATCHES}`,
        },
      },
    );
  }

  goToEdit(batchId: string) {
    void this.router.navigate(
      [`${RoutingResourceEnum.BATCHES}/${RoutingActionEnum.EDIT}/${batchId}`],
      {
        state: {
          originRoute: `${RoutingResourceEnum.BATCHES}`,
        },
      },
    );
  }

  shouldShowAttachmentIcon(rowData: ResponseBatchDto): boolean {
    return !!(
      (rowData.OMGFreeCertAttachments &&
        rowData.OMGFreeCertAttachments.length > 0) ||
      (rowData.antibioticFreeCertAttachments &&
        rowData.antibioticFreeCertAttachments.length > 0) ||
      (rowData.nutrientsFreeCertAttachments &&
        rowData.nutrientsFreeCertAttachments.length > 0)
    );
  }

  viewAttachments(rowData: ResponseBatchDto) {
    this.attachments = [];
    this.selectedAttachment = false;
    this.dialogAttachmentsVisible = true;
    this.batch = rowData;
    this.attachments = [
      ...(rowData.OMGFreeCertAttachments || []),
      ...(rowData.antibioticFreeCertAttachments || []),
      ...(rowData.nutrientsFreeCertAttachments || []),
    ];
    this.openPreview(this.attachments[0], false);
  }

  openPreview(attachment: ResponseAttachmentDto, download: boolean) {
    this.batchService
      .getAttachment(this.batch._id, attachment.fileKey)
      .subscribe({
        next: (url: string) => {
          this.pdfUrl = url;
          if (download) {
            this.downloadFile(url, attachment.originalName);
          } else {
            this.showAttachment(attachment);
          }
        },
        error: (err) => {},
      });
  }

  protected downloadFile(url: string, fileName: string | undefined) {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName || 'downloaded-file';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  private showAttachment(attachment: ResponseAttachmentDto) {
    this.selectedAttachmentName = attachment.originalName;
    this.selectedAttachmentFileKey = attachment.fileKey;
    this.selectedAttachment = true;
  }

  showPdfBlank(url: string) {
    window.open(url, '_blank');
  }

  protected readonly ExtensionsEnum = ExtensionsEnum;
}
