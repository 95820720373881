import { Component, OnInit, ViewChild } from '@angular/core';
import { LabelDescriptionCustomComponent } from '../../../../shared/components/label-description-custom/label-description-custom.component';
import { FilterSearchCustomComponent } from '../../../../shared/components/filter-search-custom/filter-search-custom.component';
import { TableCustomComponent } from '../../../../shared/components/table-custom/table-custom.component';
import { TranslateModule } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { RoutingResourceEnum } from '../../../../core/enums/routing-resource-enum';
import { ProductService } from '../../services/product.service';
import { Table } from 'primeng/table';
import { TableColumnInterface } from '../../../../shared/components/table-custom/table-custom.interface';
import { TableColumnType } from '../../../../core/enums/table-column-type';
import { Button, ButtonDirective } from 'primeng/button';
import { RoutingActionEnum } from '../../../../core/enums/routing-action-enum';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { StorageService } from '../../../../core/services/storage.service';
import { DividerModule } from 'primeng/divider';
import { DialogModule } from 'primeng/dialog';
import { ResponseAttachmentDto } from '../../../../core/models/response-attachment.dto';
import { StringifyFileSizePipe } from '../../../../core/pipe/stringify-pipe/stringify-file-size.pipe';
import { NgForOf, NgIf, UpperCasePipe } from '@angular/common';
import { DialogAttachmentCustomComponent } from '../../../../shared/components/dialog-attachment-custom/dialog-attachment-custom.component';
import { IndexResponseProductDto } from '../../dto/index-response-product.dto';
import { ExtensionsEnum } from '../../../../core/enums/extensions-enum';

@Component({
  selector: 'app-product',
  standalone: true,
  imports: [
    LabelDescriptionCustomComponent,
    FilterSearchCustomComponent,
    TableCustomComponent,
    TranslateModule,
    Button,
    ToastModule,
    DividerModule,
    DialogModule,
    ButtonDirective,
    StringifyFileSizePipe,
    NgForOf,
    DialogAttachmentCustomComponent,
    NgIf,
    UpperCasePipe,
  ],
  providers: [MessageService],
  templateUrl: './search-product.component.html',
  styleUrl: './search-product.component.scss',
})
export class SearchProductComponent implements OnInit {
  constructor(
    private router: Router,
    private productService: ProductService,
    private storageService: StorageService,
  ) {}

  dataTable!: IndexResponseProductDto[];
  searchQuery: string = '';
  @ViewChild('table') table!: Table;
  dialogImageVisible: boolean = false;
  product!: IndexResponseProductDto;
  image!: ResponseAttachmentDto[];
  imageUrl!: string;

  cols: TableColumnInterface<IndexResponseProductDto>[] = [
    {
      type: TableColumnType.STRING,
      field: 'name',
      header: 'features.product-page.table-header.product-name',
    },
    {
      type: TableColumnType.TRANSLATION,
      field: 'species.alpha3Code',
      translationKey: 'features.species.{value}',
      header: 'features.product-page.table-header.commercial-name',
    },
    {
      type: TableColumnType.STRING,
      field: 'species.scientificName',
      header: 'features.product-page.table-header.scientific-name',
    },
    {
      type: TableColumnType.STRING,
      field: 'species.alpha3Code',
      header: 'features.product-page.table-header.fao-code',
    },
    {
      type: TableColumnType.ACTION,
      header: 'features.product-page.table-header.image',
      icons: [
        {
          icon: 'pi pi-image',
          action: (rowData) => this.viewAttachment(rowData),
          showCondition: (rowData) => this.shouldShowAttachmentIcon(rowData),
        },
      ],
    },
    {
      type: TableColumnType.ACTION,
      header: '',
      icons: [
        {
          icon: 'pi pi-pencil',
          action: (rowData) => this.goToEdit(rowData._id),
        },
      ],
    },
  ];

  ngOnInit(): void {
    this.getProducts();
  }

  getProducts() {
    this.productService.index().subscribe({
      next: (products: IndexResponseProductDto[]) => {
        this.dataTable = products;
      },
      error: (err) => {},
    });
  }

  goHome() {
    void this.router.navigate([RoutingResourceEnum.HOME]);
  }

  goToInsert() {
    void this.router.navigate([
      `${RoutingResourceEnum.PRODUCTS}/${RoutingActionEnum.INSERT}`,
    ]);
  }

  goToEdit(productId: string) {
    void this.router.navigate([
      `${RoutingResourceEnum.PRODUCTS}/${RoutingActionEnum.EDIT}/${productId}`,
    ]);
  }

  shouldShowAttachmentIcon(rowData: IndexResponseProductDto): boolean {
    return rowData.attachments.length > 0;
  }

  viewAttachment(rowData: IndexResponseProductDto) {
    this.image = [];
    this.dialogImageVisible = true;
    this.product = rowData;
    this.image = rowData.attachments;
    if (this.image.length === 1) {
      this.productService
        .getImage(this.product._id, this.image[0].fileKey)
        .subscribe({
          next: (url: string) => {
            this.imageUrl = url;
          },
          error: (err) => {},
        });
    }
  }

  downloadImage() {
    if (this.image.length === 1) {
      const link = document.createElement('a');
      link.href = this.imageUrl;
      link.download = this.image[0].originalName || 'downloaded-image.jpg';
      link.click();
    }
  }

  protected readonly ExtensionsEnum = ExtensionsEnum;
}
