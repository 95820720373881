import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { TableColumnInterface } from '../../../../shared/components/table-custom/table-custom.interface';
import { TableColumnType } from '../../../../core/enums/table-column-type';
import { RoutingResourceEnum } from '../../../../core/enums/routing-resource-enum';
import { RoutingActionEnum } from '../../../../core/enums/routing-action-enum';
import { ProductionSiteService } from '../../services/production-site.service';
import { IndexResponseProductionSiteDto } from '../../dto/index-response-production-site.dto';
import { Button, ButtonDirective } from 'primeng/button';
import { FilterSearchCustomComponent } from '../../../../shared/components/filter-search-custom/filter-search-custom.component';
import { LabelDescriptionCustomComponent } from '../../../../shared/components/label-description-custom/label-description-custom.component';
import { TableCustomComponent } from '../../../../shared/components/table-custom/table-custom.component';
import { ToastModule } from 'primeng/toast';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { StringifyFileSizePipe } from '../../../../core/pipe/stringify-pipe/stringify-file-size.pipe';
import { ResponseAttachmentDto } from '../../../../core/models/response-attachment.dto';
import {
  NgClass,
  NgForOf,
  NgIf,
  NgStyle,
  UpperCasePipe,
} from '@angular/common';
import { DialogAttachmentCustomComponent } from '../../../../shared/components/dialog-attachment-custom/dialog-attachment-custom.component';
import { ExtensionsEnum } from '../../../../core/enums/extensions-enum';

@Component({
  selector: 'app-production-sites',
  standalone: true,
  imports: [
    Button,
    FilterSearchCustomComponent,
    LabelDescriptionCustomComponent,
    TableCustomComponent,
    ToastModule,
    TranslateModule,
    ButtonDirective,
    DialogModule,
    DividerModule,
    StringifyFileSizePipe,
    NgForOf,
    NgStyle,
    NgIf,
    DialogAttachmentCustomComponent,
    NgClass,
    UpperCasePipe,
  ],
  templateUrl: './search-production-site.component.html',
  styleUrl: './search-production-site.component.scss',
})
export class SearchProductionSiteComponent implements OnInit {
  constructor(
    private router: Router,
    private productionSiteService: ProductionSiteService,
  ) {}

  dialogAttachmentsVisible: boolean = false;
  searchQuery: string = '';
  @ViewChild('table') table!: Table;
  productionSite!: IndexResponseProductionSiteDto;
  attachments!: ResponseAttachmentDto[];
  selectedAttachment: boolean = false;
  pdfUrl!: string;
  selectedAttachmentName!: string;
  selectedAttachmentFileKey!: string;

  cols: TableColumnInterface<IndexResponseProductionSiteDto>[] = [
    {
      type: TableColumnType.STRING,
      field: 'name',
      header: 'features.production-site-page.table-header.place',
    },
    {
      type: TableColumnType.STRING,
      field: 'type',
      header: 'features.production-site-page.table-header.type',
    },
    {
      type: TableColumnType.STRING,
      field: 'capacity',
      header: 'features.production-site-page.table-header.capacity',
    },
    {
      type: TableColumnType.STRING,
      field: 'description',
      header: 'features.production-site-page.table-header.description',
    },
    {
      type: TableColumnType.ACTION,
      header: 'features.production-site-page.table-header.attachments',
      icons: [
        {
          icon: 'pi pi-file-o',
          action: (rowData) => this.viewAttachments(rowData),
          showCondition: (rowData) => this.shouldShowAttachmentIcon(rowData),
        },
      ],
    },
    {
      type: TableColumnType.ACTION,
      header: '',
      icons: [
        {
          icon: 'pi pi-pencil',
          action: (rowData) => this.goToEdit(rowData._id),
        },
      ],
    },
  ];
  dataTable!: IndexResponseProductionSiteDto[];

  ngOnInit(): void {
    this.getProductionSites();
  }

  getProductionSites() {
    this.productionSiteService.index().subscribe({
      next: (productionSite: IndexResponseProductionSiteDto[]) => {
        this.dataTable = productionSite;
      },
      error: (err) => {},
    });
  }

  goHome() {
    void this.router.navigate([RoutingResourceEnum.HOME]);
  }

  goToInsert() {
    void this.router.navigate([
      `${RoutingResourceEnum.PRODUCTION_SITES}/${RoutingActionEnum.INSERT}`,
    ]);
  }

  goToEdit(productionSiteId: string) {
    void this.router.navigate([
      `${RoutingResourceEnum.PRODUCTION_SITES}/${RoutingActionEnum.EDIT}/${productionSiteId}`,
    ]);
  }

  shouldShowAttachmentIcon(rowData: IndexResponseProductionSiteDto): boolean {
    return rowData.attachments.length > 0;
  }

  viewAttachments(rowData: IndexResponseProductionSiteDto) {
    this.attachments = [];
    this.selectedAttachment = false;
    this.dialogAttachmentsVisible = true;
    this.productionSite = rowData;
    this.attachments = rowData.attachments;
    this.openPreview(rowData.attachments[0], false);
  }

  openPreview(attachment: ResponseAttachmentDto, download: boolean) {
    this.productionSiteService
      .getAttachment(this.productionSite._id, attachment.fileKey)
      .subscribe({
        next: (url: string) => {
          this.pdfUrl = url;
          if (download) {
            this.downloadFile(url, attachment.originalName);
          } else {
            this.showAttachment(attachment);
          }
        },
        error: (err) => {},
      });
  }

  protected downloadFile(url: string, fileName: string | undefined) {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName || 'downloaded-file';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  private showAttachment(attachment: ResponseAttachmentDto) {
    this.selectedAttachmentName = attachment.originalName;
    this.selectedAttachmentFileKey = attachment.fileKey;
    this.selectedAttachment = true;
  }

  showPdfBlank(url: string) {
    window.open(url, '_blank');
  }

  protected readonly ExtensionsEnum = ExtensionsEnum;
}
