import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { TableColumnInterface } from '../../../../shared/components/table-custom/table-custom.interface';
import { TableColumnType } from '../../../../core/enums/table-column-type';
import { RoutingResourceEnum } from '../../../../core/enums/routing-resource-enum';
import { RoutingActionEnum } from '../../../../core/enums/routing-action-enum';
import { CustomerService } from '../../services/customer.service';
import { ResponseCustomerDto } from '../../dto/response-customer.dto';
import { Button } from 'primeng/button';
import { FilterSearchCustomComponent } from '../../../../shared/components/filter-search-custom/filter-search-custom.component';
import { LabelDescriptionCustomComponent } from '../../../../shared/components/label-description-custom/label-description-custom.component';
import { TableCustomComponent } from '../../../../shared/components/table-custom/table-custom.component';
import { ToastModule } from 'primeng/toast';
import { TranslateModule } from '@ngx-translate/core';
import { UpperCasePipe } from '@angular/common';

@Component({
  selector: 'app-customer',
  standalone: true,
  imports: [
    Button,
    FilterSearchCustomComponent,
    LabelDescriptionCustomComponent,
    TableCustomComponent,
    ToastModule,
    TranslateModule,
    UpperCasePipe,
  ],
  templateUrl: './search-customer.component.html',
  styleUrl: './search-customer.component.scss',
})
export class SearchCustomerComponent implements OnInit {
  constructor(
    private router: Router,
    private customerService: CustomerService,
  ) {}

  @ViewChild('table') table!: Table;
  searchQuery: string = '';
  cols: TableColumnInterface<ResponseCustomerDto>[] = [
    {
      type: TableColumnType.ENUM,
      // fixme recuperare il nome dell'enum
      enumName: 'buyerTypeEnum',
      field: 'buyerType',
      header: 'features.customer-page.table-header.buyer-type',
    },
    {
      type: TableColumnType.STRING,
      field: 'businessName',
      header: 'features.customer-page.table-header.business-name',
    },
    {
      type: TableColumnType.STRING,
      field: 'address',
      header: 'features.customer-page.table-header.address',
    },
    {
      type: TableColumnType.STRING,
      field: 'numberOfStores',
      header: 'features.customer-page.table-header.stores',
    },
    {
      type: TableColumnType.ACTION,
      header: '',
      icons: [
        {
          icon: 'pi pi-pencil',
          action: (rowData) => this.goToEdit(rowData._id),
        },
      ],
    },
  ];
  dataTable!: ResponseCustomerDto[];

  ngOnInit(): void {
    this.getCustomers();
  }

  getCustomers() {
    this.customerService.index().subscribe({
      next: (customer: ResponseCustomerDto[]) => {
        this.dataTable = customer;
      },
      error: (err) => {},
    });
  }

  goHome() {
    void this.router.navigate([RoutingResourceEnum.HOME]);
  }

  goToInsert() {
    void this.router.navigate([
      `${RoutingResourceEnum.CUSTOMERS}/${RoutingActionEnum.INSERT}`,
    ]);
  }

  goToEdit(customerId: string) {
    void this.router.navigate([
      `${RoutingResourceEnum.CUSTOMERS}/${RoutingActionEnum.EDIT}/${customerId}`,
    ]);
  }
}
